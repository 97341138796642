.text-area {
	@apply mb-4 mt-2 px-6 text-sm2 font-light sm:px-8 lg:px-0;
}

.text-area strong {
	font-weight: 600;
}

.text-area p {
	margin: 1em 0;
}
